import styled from "styled-components"

const FeedStyled = styled.div`
  @media (max-width: 1080px) {
    display: flex;
    flex-wrap: wrap;
  }
  .feed {
    @media (max-width: 1080px) {
      margin: 0 auto;
    }
  }
  .container {
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    &__category {
      align-items: center;
      padding-bottom: 110px;
      @media (max-width: 1080px) {
        padding-bottom: 70px;
      }
    }
    &__title {
      @media (max-width: 1080px) {
        width: 100%;
      }
    }
    &__feed {
      padding-bottom: 70px;
      @media (max-width: 1080px) {
        padding-bottom: 70px;
      }
    }
  }
  h2 {
    width: 100%;
    padding-top: 60px;
    margin-bottom: 80px;
    @media (max-width: 1880px) {
      padding-top: 30px;
      margin-bottom: 30px;
    }
  }
  .blogPage__category {
    margin: 0;
    margin-right: 15px;
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 20px;
    &__item {
      margin: 0;
      margin-right: 15px;
      padding: 8px 25px;
      border: 1px solid #000;
      font-size: 17px;
      font-weight: 500;
      cursor: pointer;
      margin-bottom: 20px;
      transition: 0.3s all;
      @media (max-width: 1080px) {
        padding: 5px 12px;
      }
      &:hover {
        background: #000;
        color: #fff;
      }
      &--active {
        background: #000;
        color: #fff;
      }
    }
  }
  .blog__expert {
    max-width: 390px;
    @media (max-width: 1280px) {
      max-width: 310px;
    }
    &__heading {
      padding-bottom: 50px;
      font-size: 20px;
      font-weight: 500;
      margin: 0;
    }
    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &__img {
      width: 100px;
      margin-right: 25px;
    }
    &__descirption {
      p {
        font-size: 19px;
        font-weight: 500;
        margin: 0;
      }
    }
  }
  p.blog__expert__descirption__position {
    font-weight: 300;
    font-size: 17px;
    margin: 0;
  }

  .blog__feed {
    max-width: 670px;
    width: 100%;
    display: flex;
    /* justify-content: space-between; */
    @media (max-width: 1280px) {
      max-width: 620px;
    }
    &--2 {
      justify-content: center;
      & > div,
      & > a {
        max-width: 320px;
        width: calc(50% - 15px);
        @media (max-width: 600px) {
          max-width: 100% !important;
          width: 100%;
          padding-bottom: 30px;
          &:last-of-type {
            padding-bottom: 0;
          }
        }
        & > div {
          height: 100%;
        }
      }
      .feed__box {
        flex-direction: column;
        display: flex;
      }
      .feed__box__button {
        margin-top: auto;
      }
      .feed__box__title {
        padding-bottom: 15px;
      }
      .feed__item {
        &:nth-of-type(2n) {
          margin-right: 0;
        }
        & > a {
          position: relative;
          z-index: 2;
          /* background: #fff; */
          height: 100%;
          width: 100%;
        }
        & > div {
          position: relative;
          z-index: 2;
          /* background: #fff; */
          height: 100%;
          width: 100%;
        }
      }
    }
    &--3 {
      & > div,
      & > a {
        /* max-width: 320px; */
        width: 100%;
        width: calc(33.33% - 20px);
        @media (max-width: 1080px) {
          width: calc(50% - 20px);
        }
        @media (max-width: 880px) {
          width: 100%;
        }
      }
      .feed__item {
        margin-bottom: 35px;
        & > div {
          display: flex;
          height: 100%;
          width: 100%;
        }
        .feed__box {
          display: flex;
          flex-direction: column;
          margin-bottom: 0;
        }
        .feed__box__button {
          margin-top: auto;
        }
        .feed__box__title {
          padding-bottom: 20px;
        }
        &:nth-of-type(3n) {
          margin-right: 0;
        }
        @media (max-width: 1080px) {
          &:nth-of-type(3n) {
            margin-right: 30px;
          }
          &:nth-of-type(2n) {
            margin-right: 0px;
          }
        }
        @media (max-width: 880px) {
          &:nth-of-type(3n) {
            margin-right: 0px;
          }
          &:nth-of-type(2n) {
            margin-right: 0px;
          }
        }
      }
    }
    .feed__box {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .feed__box__button {
      margin-top: auto;
    }
  }
  .case__feed--2 {
    .feed__box__title {
      font-size: 30px;
    }
    & > div {
      max-width: 380px;
      width: calc(50% - 15px);
      @media (max-width: 880px) {
        max-width: 100% !important;
        width: 100%;
        padding-bottom: 30px;
        &:last-of-type {
          padding-bottom: 0;
        }
      }
      @media (max-width: 500px) {
        /* padding-bottom: 00px; */
      }
      & > div {
        height: 100%;
      }
    }
  }
  .feed__item {
    margin-right: 30px;
    width: 100%;
    @media (max-width: 880px) {
      margin-right: 0px;
    }
    a {
      display: flex;
      height: 100%;
      /* width: 100%; */
      & > div {
        width: 100%;
        height: 100%;
      }
    }
  }
  .feed__wrapper {
    max-width: 850px;
    margin: 0 auto;
    justify-content: space-between;
    position: relative;
    @media (max-width: 880px) {
      max-width: 600px;
      padding: 0 40px;
    }
    @media (max-width: 600px) {
      max-width: 600px;
      padding: 0 20px;
    }
    &.blog__feed--2 > a,
    &.blog__feed--2 > div {
      max-width: 380px;
      @media (max-width: 880px) {
        max-width: 100%;
        width: 100%;
        padding-bottom: 50px;
      }
    }
  }
  .feed__icon {
    position: absolute;
    ${props => (props.iconPosition == "top" ? "top:-50px" : null)};
    ${props =>
      props.iconPosition == "middle"
        ? "top:50%; transform: translateY(-50%);"
        : null};
    ${props => (props.iconPosition == "bottom" ? "bottom:50px" : null)};
    background: url(${props => props.icon});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    left: -110px;
    width: 210px !important;
    height: 210px;
  }
  .casestudies__feed {
    .feed__box__title {
      min-height: 50px !important;
    }
  }
`

export default FeedStyled
