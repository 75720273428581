import styled from "styled-components"

const titleStyled = styled.div`
  width: 100%;
  h1 {
    font-size: 32px;
    font-weight: 600;
    color: ${props => (props.headingColor ? props.headingColor : props.color)};
    text-align: ${props => props.textAlign};
    display: flex;
    flex-direction: column;
    position: relative;
    @media (max-width: 1280px) {
      font-size: 27px;
    }
    ${props =>
      props.showLine
        ? `&:before{
      content: '';
      position: absolute;
      display: block;
      transform: translate(-50%);
      width: 280px;
      height: 1px;
      background: #333333;
      top:27px;
    }`
        : ""};
    &:after {
      content: "";
      margin: ${props => {
        switch (props.linePosition) {
          case "left":
            return "25px auto 0 0"

          case "center":
            return "25px auto"

          case "right":
            return "25px 0 0 ato"

          default:
            return "25px auto"
        }
      }};
      display: inline-block;
      width: 70px;
      height: 1px;
      ${props =>
        props.lineColor
          ? "background:" + props.lineColor
          : "background:#707070"}
    }
  }
  h2 {
    font-size: 32px;
    line-height: 1.3;
    font-weight: 900;
    color: ${props => (props.headingColor ? props.headingColor : props.color)};
    text-align: ${props => props.textAlign};
    display: flex;
    flex-direction: column;
    position: relative;
    @media (max-width: 1280px) {
      font-size: 27px;
    }
    @media (max-width: 1080px) {
      font-size: 23px;
      br {
        display: none;
      }
    }
    @media (max-width: 880px) {
      margin-bottom: 40px;
    }
    :after {
      content: "";
      margin: ${props => {
        switch (props.linePosition) {
          case "left":
            return "25px auto 0 0"

          case "center":
            return "25px auto"

          case "right":
            return "25px 0 0 ato"

          default:
            return "25px auto"
        }
      }};
      display: inline-block;
      width: 70px;
      height: 1px;
      ${props =>
        props.lineColor
          ? "background:" + props.lineColor
          : "background:#707070"}
    }
    @media (max-width: 880px) {
      :after {
        /* display: none !important; */
        ${props => (props.lineColor == "rgb(0, 0, 0)" ? "display:none" : "")}
      }
    }
    ${props =>
      props.showLine
        ? `&:before{
      content: '';
      position: absolute;
      display: block;
      width: 280px;
      height: 1px;
      background: #333333;
      top: 27px;
      ${
        props.position === "right"
          ? "transform: translate(-50%,-50%); left: -50%;"
          : "transform: translate(50%, -50%); right: -50%;"
      }
      
    }`
        : ""};
  }
  h3 {
    font-size: 24px;
    font-weight: 600;
    color: ${props => (props.headingColor ? props.headingColor : props.color)};
    text-align: ${props => props.textAlign};
    display: flex;
    flex-direction: column;
    :after {
      content: "";
      margin: ${props => {
        switch (props.linePosition) {
          case "left":
            return "20px auto 0 0"

          case "center":
            return "20px auto"

          case "right":
            return "20px 0 0 ato"

          default:
            return "20px auto"
        }
      }};
      display: inline-block;
      width: 70px;
      height: 1px;
      ${props =>
        props.lineColor
          ? "background:" + props.lineColor
          : "background:#707070"}
    }
  }
`

export default titleStyled
