import styled from "styled-components"

const FeedBoxStyled = styled.div`
  height: 100%;

  .feed__box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 5px 15px rgba(112, 112, 112, 0.2);
    padding-bottom: 35px;
    margin-bottom: 35px;
    background: #fff;
    transition: 0.3s all;
    height: calc(100% - 35px);
    width: 100%;
    &:hover {
      box-shadow: 0px 5px 15px rgba(112, 112, 112, 0.4);
      transform: scale(1.02);
    }
    &__img {
      background: url(${props => props.img});
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 135px;
    }
    &__title {
      font-size: 20px;
      line-height: 26px;
      font-weight: 500;
      text-align: center;
      margin: 25px 0 0;
      padding: 0 20px;
      /* font-size: 30px;
      line-height: 34px; */
      font-weight: 900;
      /* min-height: 60px; */
      @media (max-width: 1280px) {
        font-size: 25px;
        line-height: 29px;
      }
      @media (max-width: 1080px) {
        font-size: 18px;
        line-height: 24px;
      }
      @media (max-width: 880px) {
        font-size: 30px;
        line-height: 34px;
      }
      @media (max-width: 550px) {
        font-size: 22px;
        line-height: 28px;
      }
    }
    &__button {
      margin-top: 25px;
    }
    &__meta {
      padding: 0 20px;
      text-align: center;
      &:after {
        content: "";
        display: block;
        width: 60px;
        height: 1px;
        background: #000;
        margin: 15px auto 20px;
      }
    }
    &__authore {
    }
    &__date {
    }
    .feed__box__descirption {
      padding: 0 20px;
      font-size: 16px;
      font-weight: 300;
      text-align: center;
      min-height: 75px;
    }
  }
`

export default FeedBoxStyled
