import styled, { css } from "styled-components"

const ButtonStyled = styled.div`
  display: flex;
  justify-content: center;
  span,
  button,
  a {
    border: 1px solid ${props => (props.dark ? "#000" : "#fff")};
    padding: 12px 29px;
    color: ${props => (props.dark ? "#000" : "#fff")};
    background: transparent;
    cursor: pointer;
    font-weight: 600;
    line-height: 1.5;
    font-size: 17px;
    transition: 0.3s all;
    text-align: center;

    ${props =>
      props.shadow &&
      css`
        border: none;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
      `}

    &:hover {
      ${props =>
        props.dark
          ? `color:#fff; background:#000;`
          : `color:#000; background:#fff;`};

      .icon {
        ${props =>
          props.dark
            ? `filter: brightness(0) invert(1);`
            : `filter: brightness(0);`};
      }
    }

    .icon {
      display: inline-block;
      margin-left: 8px;
      width: 15px;
      height: 15px;
      transition: 0.3s all;
      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
`

export default ButtonStyled
