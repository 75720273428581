import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import uuid from "react-uuid"
import TitleStyled from "../components/styles/titleStyles"
import FeedStyled from "../components/styles/FeedStyles"
import FeedBox from "../components/feedBox"
import myInitObject from "../ultis/variable"
import SEO from "../components/seo"

const IndexPage = ({ data, pageContext }) => {
  const { caseStudyCollection, caseStudySingleton } = data.swapi
  const [selectedCategory, setSelectedCategory] = useState("")
  const [postList, setPostList] = useState([])

  let listCategory = []

  useEffect(() => {
    setPostList(caseStudyCollection)
  }, [])

  caseStudyCollection.map(category => {
    category.feedCategory.map(item => {
      if (!listCategory.includes(item.replace(/(^[\s]+|[\s]+$)/g, ""))) {
        listCategory.push(item.replace(/(^[\s]+|[\s]+$)/g, ""))
      }
    })
  })

  function setCategory(category) {
    setSelectedCategory(category)
    let listPostTmp = caseStudyCollection.map(item => {
      let tmp = item.feedCategory.map(el => el.replace(/(^[\s]+|[\s]+$)/g, ""))
      if (tmp.includes(category)) {
        return item
      }
    })
    let listPost = listPostTmp.filter(function(el) {
      return el != null
    })
    setPostList(listPost)
  }

  return (
    <Layout pageContext={pageContext}>
      <SEO
        title={caseStudySingleton.meta_title}
        description={caseStudySingleton.meta_description}
        img={
          caseStudySingleton.meta_img ? caseStudySingleton.meta_img.path : ""
        }
        lang="pl"
      />
      <section className="blogPage casestudiesPage">
        <FeedStyled>
          <div className="container container__title">
            <TitleStyled
              textAlign="center"
              linePosition="center"
              color="#000000"
            >
              <h2
                dangerouslySetInnerHTML={{ __html: caseStudySingleton.heading }}
              ></h2>
            </TitleStyled>
            <p className="casestudies__desription">
              {caseStudySingleton.description}
            </p>
          </div>
          <div className="container container__category">
            <p className="blogPage__category">
              {caseStudySingleton.categotyText}
            </p>
            {listCategory.length
              ? listCategory.map(category => (
                  <p
                    key={uuid()}
                    className={`blogPage__category__item ${
                      category === selectedCategory
                        ? "blogPage__category__item--active"
                        : ""
                    }`}
                    onClick={() => setCategory(category)}
                  >
                    {category}
                  </p>
                ))
              : null}
          </div>
          <div className="container casestudies__feed container__feed blog__feed--3">
            {postList
              ? postList.map(item => (
                  <div key={uuid()} className="feed__item">
                    <FeedBox
                      img={myInitObject.cockpitUrl + item.feedImage.path}
                      title={item.feedTitle}
                      descirption={item.feedDescription}
                      link={`/casestudies/${item.slug.replace(
                        "/casestudies/",
                        ""
                      )}`}
                      linkPostion="bottom"
                      // linkText={item.feedTextButton}
                      titleSize="30"
                      linkText={item.feedTextButton}
                    />
                  </div>
                ))
              : null}
          </div>
        </FeedStyled>
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query caseStudyPL {
    swapi {
      caseStudyCollection(lang: "pl") {
        feedCategory
        feedDescription
        feedTextButton
        feedImage {
          path
        }
        feedTitle
        metaDescription
        metaImage {
          path
        }
        metaTitle
        slug
      }
      caseStudySingleton(lang: "pl") {
        meta_title
        meta_description
        meta_img {
          path
        }
        heading
        description
        categotyText
      }
    }
  }
`
