import React from "react"
import Link from "gatsby-link"
import ButtonStyled from "./styles/ButtonStyles"
import FeedBoxStyled from "./styles/FeedBoxStyles"

const FeedBox = ({
  img,
  title,
  date,
  author,
  descirption,
  link,
  linkText,
  titleSize,
  linkPostion,
}) => {
  return (
    <>
      {linkText ? (
        <Link to={link}>
          <FeedBoxStyled titleSize={titleSize} img={img}>
            <div className="feed__box">
              {img ? <div className="feed__box__img"></div> : ""}
              {title ? (
                <p
                  className="feed__box__title"
                  dangerouslySetInnerHTML={{ __html: title }}
                ></p>
              ) : null}
              {linkPostion != "bottom" ? (
                <div className="feed__box__button">
                  <ButtonStyled dark={true}>
                    <Link to={link}>{linkText}</Link>
                  </ButtonStyled>
                </div>
              ) : null}
              {author || date ? (
                <p className="feed__box__meta">
                  {author ? (
                    <span className="feed__box__author">{author}</span>
                  ) : null}
                  , &nbsp;
                  {date ? (
                    <span className="feed__box__date">{date}</span>
                  ) : null}
                </p>
              ) : null}
              {descirption ? (
                <p
                  className="feed__box__descirption"
                  dangerouslySetInnerHTML={{ __html: descirption }}
                ></p>
              ) : null}
              {linkPostion == "bottom" ? (
                <div className="feed__box__button">
                  <ButtonStyled dark={true}>
                    <Link to={link}>{linkText}</Link>
                  </ButtonStyled>
                </div>
              ) : null}
            </div>
          </FeedBoxStyled>
        </Link>
      ) : (
        <Link to={link}>
          <FeedBoxStyled titleSize={titleSize} img={img}>
            <div className="feed__box">
              <div className="feed__box__img"></div>
              {title ? (
                <p
                  className="feed__box__title"
                  dangerouslySetInnerHTML={{ __html: title }}
                ></p>
              ) : null}
              {author || date ? (
                <p className="feed__box__meta">
                  {author ? (
                    <span className="feed__box__author">{author}</span>
                  ) : null}
                  , &nbsp;
                  {date ? (
                    <span className="feed__box__date">{date}</span>
                  ) : null}
                </p>
              ) : null}
              {descirption ? (
                <p
                  className="feed__box__descirption"
                  dangerouslySetInnerHTML={{ __html: descirption }}
                ></p>
              ) : null}
            </div>
          </FeedBoxStyled>
        </Link>
      )}
    </>
  )
}

export default FeedBox
